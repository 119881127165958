import React ,{useState }from "react";
import "./Business.css";

const Business = () => {

  const [phone, setPhone] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 10 characters
    if (/^\d{0,10}$/.test(value)) {
      setPhone(value);
    }
  };
  return (
    <div className="business-container">
      <h1>Business Enquiry</h1>

      <div className="business-layout">
        {/* Left Side: Four Boxes */}
        <div className="info-boxes">
          <div className="info-box">
            <i className="fas fa-phone-alt"></i>
            <div className="co-no">Contact Number</div>
            <a href="tel:+918208937591">+91 82089 37591</a>
          </div>
          <div className="info-box">
            <i className="fas fa-envelope"></i> {/* Email Icon */}
            <div className="co-no">Email</div>
            <a href="mailto:snehalsp65@gmail.com">snehalsp65@gmail.com</a>
          </div>
          <div className="info-box">
            <i className="fas fa-map-marker-alt"></i> {/* Address Icon */}
            <div className="co-no">Address</div>
            <p>
              Office No . 1007 Jasmine -D Lodha Crown Taloja, Pin-code 421204
            </p>
          </div>

          <div className="info-box">
            <i className="fas fa-linkedin"></i> {/* LinkedIn Icon */}
            <div className="co-no">LinkedIn</div>
            <a href="https://www.linkedin.com">LinkedIn Profile</a>
          </div>
        </div>

        {/* Right Side: Form */}
        <div className="form-container">
          <h2>Connect with Us to Hire Skilled Students</h2>
          <form className="form1">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              required
            />

            <label htmlFor="phone">Contact Number:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your contact number"
              value={phone}
              onChange={handleChange}
              required
            />

            <label htmlFor="company">Name of the Company:</label>
            <input
              type="text"
              id="company"
              name="company"
              placeholder="Enter your Company Name"
              required
            />

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Business;
